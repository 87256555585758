import axios from 'axios'
import {
	MessageBox,
	Message
} from 'element-ui'
import store from '@/store'
import router from '@/router'
import {
	getToken
} from '@/utils/auth.js'
// import store from '@/store/index.js'
import qs from 'qs'


const service = axios.create({
	// baseURL: 'http://192.168.1.5:8280/jeecg-boot', 
	// baseURL: 'https://api2.cc007.com/jeecg-boot', 
	
	baseURL: 'https://api.cc007.com/jeecg-boot', 
	timeout: 50000, // request timeout
	headers: {
		'Content-Type': 'application/json',
		// 'X-Access-Token':getToken()
		
	},
	crossDomain: true // 设置cross跨域
	// withCredentials: true//设置cross跨域 并设置访问权限 允许跨域携带cookie信息
})

// request interceptor
service.interceptors.request.use(
	config => {
		config.headers['X-Access-Token'] = getToken()
		return config
	},
	error => {
		// do something with request error
		// Message({
		// 	message: error,
		// 	type: 'error',
		// 	duration: 5 * 1000
		// })
		return error
	}
)


service.interceptors.response.use(
	response => {
		const res = response.data

		if (res.code == '200') {
			return res
		}else if(res.code == '502'){
			return res
		}else if(res.code == '497' || res.code == '498' || res.code == '499'|| res.code == '494'){
			store.commit('loginOut')
			setTimeout(()=>{
				router.push('/login/login')
			},1500)
			Message({
				message: res.message,
				type: 'error',
				duration: 5 * 1000
			})
			return res
		} else {
			Message({
				message: res.message,
				type: 'error',
				duration: 5 * 1000
			})
			return res
		}
	},
	error => {
		// Message({
		// 	message: error.message,
		// 	type: 'error',
		// 	duration: 5 * 1000
		// })
		return error
	}
)

export default service