<template>
	<div style="background-color: #F7F7F7;" ref="chatCenter" @scroll="chatCenter">
		<head-bar v-if="headShow"></head-bar>

		<!-- 热门游戏 -->
		<div class="content" style="margin-top: 30px;">
			<el-row>
				<el-col :span="24">
					<el-row :gutter="20">
						<el-col :span="18">
							<div
								style="padding: 10px 20px;background-color: #fff;width: calc(100% - 40px);margin-bottom: 30px ;flex-direction: column;display: flex;">
								<div>
									<div class="floorLeft">
										<span>选择游戏分类</span>
									</div>
									<div class="game" v-if="gameList.length != 0">

										<div class="gameRight">
											<div class="rightItem" v-for="item in gameList.slice(0, 12)">
												<img @click="selectGames(item)"
													:class="{'selectAction':search.gameId == item.id}"
													style="border-radius: 10px;width:70px;height:70px;"
													:src="item.icon" />
												<div @click="selectGames(item)" class="itemName el1">{{item.name}}
												</div>
											</div>
										</div>

									</div>
								</div>
								<div class="fl" style="width: 60%;margin-bottom: 20px;">
									<div style="margin-right: 40px;">
										<!-- <img v-if="Object.keys(selectGame1).length != 0" :src="selectGame1.icon"
												style="width:100px;height: 100px;border-radius: 10px;margin-left: 30px;" /> -->
									</div>
									<div>
										<span class="classity"
											:class="{'classityAct' : search.gameCommodityCategory == 2}"
											@click="search.gameCommodityCategory = 2;search.pageNo = 1;getGameCommodityList()">代练套餐</span>
										<span :class="{'classityAct' : search.gameCommodityCategory == 8}"
											@click="search.gameCommodityCategory = 8;search.pageNo = 1;getGameCommodityList()"
											class="classity">接单大厅</span>
										<span
											@click="search.gameCommodityCategory = -1;storeSearch.pageNo = 1;getNoRecommend()"
											class="classity"
											:class="{'classityAct' : search.gameCommodityCategory == -1}">代练商家</span>

									</div>

								</div>
							</div>
						</el-col>

						<el-col :span="6">
							<div v-if="token == undefined">
								<el-card shadow="hover" style="height: 370px;padding: 20px;">
									<div class="loginText">
										<div class="login" :class="{'actionLogin' : loginType == 0}"
											@click="swiperType(0)">短信登录
										</div>
										<div class="login1" :class="{'actionLogin' : loginType == 1}"
											@click="swiperType(1)">账户登录
										</div>
									</div>

									<div v-if="loginType == 0">
										<el-form :validate-on-rule-change="false" :model="loginForm" :rules="loginRule"
											ref="loginForm">
											<el-form-item label="" prop="account">
												<el-input v-prevent-space v-model="loginForm.account" type="number"
													placeholder="手机号">
													<template slot="prepend">+86</template>
												</el-input>
											</el-form-item>
											<el-form-item label="" prop="code">
												<div class="send">
													<el-input v-prevent-space v-model="loginForm.code" type="number"
														placeholder="请输入验证码">
													</el-input>
													<span @click="getCodes('1',loginForm.account)"
														class="getCode">{{ countDown || '获取验证码'}}</span>
												</div>
											</el-form-item>
										</el-form>
									</div>
									<div v-if="loginType == 1">
										<el-form :validate-on-rule-change="false" :model="loginPassForm"
											:rules="loginPassRule" ref="loginPassForm">
											<el-form-item label="" prop="account">
												<el-input v-prevent-space placeholder="手机号" type="number"
													v-model="loginPassForm.account">
													<template slot="prepend">+86</template>
												</el-input>
											</el-form-item>
											<el-form-item label="" prop="password">
												<el-input v-prevent-space v-model="loginPassForm.password" show-password
													placeholder="请输入登录密码">
												</el-input>
											</el-form-item>
										</el-form>
									</div>
									<div class="feature">
										<p class="feature1" @click="$router.push('/login/forget');">忘记密码</p>
										<p class="feature2" @click="$router.push('/login/register');">立即注册</p>
									</div>
									<el-button @click="login"
										style="width: 100%;background-color: #0085FF;border-radius: 8px;height: 42px;font-size: 16px;"
										type="primary">立即登录</el-button>
									<div class="agreement">
										<el-checkbox v-model="checked"></el-checkbox>
										<div style="font-size: 16px;white-space:normal;margin-left: 10px;">我已阅读并同意<span
												style="color: #0085FF;"
												@click="$router.push('/serviceCenter/3/1804450566674083841')">《用户协议》</span>和<span
												style="color: #0085FF;"
												@click="$router.push('/serviceCenter/3/1806708534589366274')">《隐私政策》</span>
										</div>
									</div>
									<!-- <div style="display: flex;justify-content: center;margin-top: 20px;">
										<div
											style="font-size: 16px;white-space:normal;margin-left: 10px;color: #A5A7B1;">
											—— 第三方登录 ——</div>
									</div>
									<div
										style="display: flex;justify-content: space-around;margin-top: 20px;width: 60%;margin-left: 20%;">
										<img src="@/static/qq.png" style="width: 48px;height: 48px;" />
										<img @click="weixinLogin" src="@/static/weixin.png"
											style="width: 48px;height: 48px;" />
									</div> -->
								</el-card>
							</div>

							<div style="height: 410px;display: flex;flex-direction: column;justify-content: space-between;"
								v-else>
								<el-card shadow="hover" style="height: 186px;padding: 20px;">
									<div class="userInfo">

										<img @click="$router.push('/mineIndex/1')"
											:src="userInfo.portrait || require('@/static/image/index/profile.png')"
											class="infoImage" />
										<div @click="$router.push('/mineIndex/1')" class="userName fl">
											<div style="color: #0085FF;max-width: 130px;" class="el1">{{userInfo.name}}
											</div>
											，欢迎回来
										</div>



										<div class="userList">
											<div class="userItem">
												<div class="userBtn" @click="toPubilc2(8)">发布代练需求</div>
											</div>
											<div class="userItem">
												<div class="userBtn1" @click="toPubilc2(2)">
													发布代练套餐</div>
											</div>
										</div>

									</div>
								</el-card>
								<el-card shadow="hover" style="height: 138px;padding: 20px;">
									<div class="equityList">
										<div class="equityItem" v-for="(item,index) in equityList">
											<img :src="item.image" style="width: 24px;height: 24px;" />
											<span style="margin-top: 8px;">{{item.text}}</span>
										</div>
									</div>
								</el-card>
							</div>

						</el-col>

					</el-row>
				</el-col>

			</el-row>
		</div>



		<div style="margin-top: 10px;">
			<el-row style="margin: 0 auto;" class="content">

				<el-col :span="24">
					<div id="scolTo">
						<div v-if="(search.gameCommodityCategory == 2 || search.gameCommodityCategory == 8) && gameList1.length != 0"
							class="fl" style="padding: 10px 20px;background-color: #fff;width: calc(100% - 40px);">
							<div class="fl" style="width: 40%;">
								<div class="sortList1 fl">

									<div class="sortItem1" @click="typeSearch(2)"
										:class="{'sortActin':search.orderType == 2}">
										<span>时间</span>
										<div class="fl" style="flex-direction: column;">
											<i class="el-icon-caret-top"></i>
											<i style="margin-top: -10px;" class="el-icon-caret-bottom"></i>
										</div>

									</div>
									<div class="sortItem1 fl" style="margin-left: 20px;" @click="typeSearch(3)">
										<span
											:class="{'sortActin':search.orderType == 4 || search.orderType == 5}">价格</span>
										<div class="fl" style="flex-direction: column;">
											<i :class="{'sortActin':search.orderType == 4 }"
												class="el-icon-caret-top"></i>
											<i :class="{'sortActin':search.orderType == 5 }" style="margin-top: -10px;"
												class="el-icon-caret-bottom"></i>
										</div>
									</div>
								</div>
							</div>

							<div style="color: #000;font-size: 14px;font-weight: bold;width: 25%;text-align: center;">
								保证金</div>
							<div style="color: #000;font-size: 14px;font-weight: bold;width: 25%;text-align: center;">
								代练费用</div>

							<div v-if="gameList1.length != 0" class="publish" @click="toPublic">
								<span style="font-size: 14px;">
									<i class="el-icon-plus"></i>
									快捷发布
								</span>
								<span style="font-size: 12px;">
									快捷发布当前类目商品
								</span>
							</div>
						</div>

						<div v-if="(search.gameCommodityCategory == 2 || search.gameCommodityCategory == 8) && gameList1.length != 0"
							class="screenItem fl" v-for="(item,index) in gameList1">
							<div class="fl guarantee">
								<div style="width: 40%;display: flex;">
									<el-image style="width: 198px; height: 128px;border-radius: 10px;flex-shrink: 0;"
										:src="item.pic.split(',')[0]" fit="cover"></el-image>
									<div>
										<div
											style="margin-left: 15px;display: flex;flex-direction: column;justify-content: space-around; ">
											<div class="infoTitle el2">
												<span
													@click="toDetaile(item)">【{{item.gameId_dictText}}】{{item.title}}</span>
											</div>
											<div class="area">
												<span v-if="item.shopId == null"
													style="color: #A5A7B1;">代练身份：普通代练&emsp;&emsp;代练时间:{{item.dailianTime}}小时</span>
												<span v-else
													style="color: #A5A7B1;">代练身份：认证代练&emsp;&emsp;代练时间:{{item.dailianTime}}小时</span>
											</div>
											<div>
												<div class="area"
													v-if="item.shopDetail != null &&  item.shopDetail != ''">
													<span style="color: #A5A7B1;">代练介绍:</span>
													<span style="color: #A5A7B1;">{{item.shopDetail}}</span>
												</div>
												<div class="area" v-else>
													<span style="color: #A5A7B1;">详情:</span>
													<span style="color: #A5A7B1;">{{item.detail}}</span>
												</div>
												<div class="area">
													<span style="color: #A5A7B1;">发布时间：{{item.createTime}}</span>
												</div>
											</div>


										</div>
									</div>
								</div> 
								<div class="accountPrice">￥{{item.trainDeposit}}</div>
								<div class="accountNumber">￥{{item.price}}</div>
								<div class="accountPay" style="display: flex;flex-direction: column;">
									<div v-if="item.gameCommodityCategory == 8" @click="toOrderPay(item)"
										class="accountDetaile">立即接单</div>
									<div v-if="item.gameCommodityCategory == 2" @click="toOrderPay(item)"
										class="accountDetaile">邀请代练</div>
									<div v-if="item.gameCommodityCategory == 2" @click="dialogInfo(item)"
										class="accountDetaile1">联系代练</div>
								</div>
							</div>
						</div>

						<div v-if="search.gameCommodityCategory == -1" class="screenItem fl"
							v-for="(item,index) in noRecommendedList">
							<div class="fl guarantee">
								<div style="width: 40%;display: flex;">
									<el-image style="width: 148px; height: 148px;border-radius: 10px;flex-shrink: 0;"
										:src="item.logo || require('@/static/image/index/hotShop1.png')"
										fit="cover"></el-image>
									<div>
										<div
											style="margin-left: 15px;display: flex;flex-direction: column;justify-content: space-around; height: 148px;">
											<div class="infoTitle el2">
												<span>{{item.storeName}}</span>
											</div>
											<div>
												<div class="area">
													<span style="color: #A5A7B1;">代练游戏：{{item.gameIds_dictText}}</span>
												</div>
												<div class="area">
													<span style="color: #A5A7B1;">入驻时间：{{item.createTime}}</span>
												</div>
											</div>


										</div>
									</div>
								</div>
								<div class="accountPrice">&ensp;</div>
								<div class="accountNumber">&ensp;</div>
								<div class="accountPay" style="display: flex;flex-direction: column;">

									<div @click="dialogInfo(item)" class="accountDetaile1">联系代练</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>

			</el-row>
		</div>


		<shopping-card></shopping-card>
		<slidingBlock :shoWData="shoWData" @submit="verifySubmit"></slidingBlock>
		<footer-bars></footer-bars>
	</div>

</template>

<script>
	import {
		MessageBox,
	} from 'element-ui'
	import {
		login
	} from '@/api/login.js'
	import footerBars from '@/components/footerBars.vue';
	import {
		settledStoreList,
		noticeList,
		gameCommodityCategories,
		gameList,
		gameCommodityTypes,
		districtList,
		userCenter,
		hotList,
		gameBanner,
		tradeList,
		queryPic,
		commonProblemList,
		protocolList,
		gameCommodityList,
		realInfo,
		sessionRecordAdd,
		storeAccountList,
		listDailian
	} from '@/api/index.js'
	import shoppingCard from '@/components/shoppingCart.vue';
	import headBar from '@/components/HeadBar.vue';
	import {
		getToken,
		setToken,
		setCookie,
		getCookie,
		removeToken
	} from '@/utils/auth.js'
	import slidingBlock from '@/components/slidingBlock.vue';

	export default {
		name: 'gameLeveling',
		components: {
			shoppingCard,
			headBar,
			slidingBlock,
			footerBars
		},
		activated() {

		},
		data() {
			return {
				shoWData: false,
				lableAction: 0,
				headShow: true,
				hoverIndex: 0,
				hoverShow: false,
				userInfo: {},
				token: undefined,
				selectShow: false,
				selectShow1: false,
				selectShow2: false,
				selectShow3: false,
				selectShow4: false,
				selectShow5: false,
				// 验证码登录表单
				loginForm: {
					account: '',
					code: ''
				},
				// 密码登录表单
				loginPassForm: {
					account: '',
					password: ''
				},
				// 倒计时
				countDown: 0,
				countdownInterval: null,
				// 密码登录验证
				loginPassRule: {
					account: [{
							required: true,
							message: '请输入登录手机号',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^1\d{10}$/,
							message: '手机号格式不正确',
							trigger: 'blur'
						}
					],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				// 验证码登录验证
				loginRule: {
					account: [{
							required: true,
							message: '请输入登录手机号',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^1\d{10}$/,
							message: '手机号格式不正确',
							trigger: 'blur'
						}
					],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},
				loginType: 0,
				searchKey: '',
				equityList: [{
						image: require('@/static/image/index/equity1.png'),
						text: '交易安全',

					},
					{
						image: require('@/static/image/index/equity2.png'),
						text: '押金赔付'
					},
					{
						image: require('@/static/image/index/equity3.png'),
						text: '客服监督'
					},
					{
						image: require('@/static/image/index/equity4.png'),
						text: '闪电上号'
					},
					{
						image: require('@/static/image/index/equity5.png'),
						text: '资金保证'
					},
					{
						image: require('@/static/image/index/equity6.png'),
						text: '安全无忧'
					}
				],
				funList: [],
				// 热门标签
				hotTagList: [],

				// 热门游戏列表
				hotSort: [],
				// 公告列表
				noticeList: [],
				//首字母
				initial: ['热门游戏', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
					'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
				],
				gameSearch: {
					initial: '',
					name: '',
					pageNo: 1,
					pageSize: 1000,
					terminalType: '',
					sortType: '2'

				},
				selectGame1: {},
				search: {
					orderType: 2,
					pageNo: 1,
					pageSize: 200,
					gameCommodityCategory: 2,
					gameId: '',
				},
				gameList: [],
				selectGame: {},

				gameTypeList: [],
				selectGameTypeList: {},

				sonGameTypeList: [],
				selectSonGameTypeList: {},

				// 区服列表
				districtList: [],
				selectDistrict: {},

				// 大区
				sonDistrictList: [],
				sonSelectDistrict: {},
				// 系统
				systemList: [],
				selectSystem: {},

				// 轮播图列表
				bannerList: [],


				// 适应屏幕分辨率
				noticeWidth: 700,
				// 公告展示（过长时会重复两遍）
				showNotice: '',
				// 用于公告过长时，获取重复两遍中第一遍的长度
				notice: '',
				// 公告初始位置
				marginLeft: 150,
				noticeData: [],
				checked: false,


				// 网游数据
				onlineGameList: [],
				// 手游数据
				handGameList: [],
				queryPicList: {
					f1: {},
					f1Pics: [],
					f2: {},
					f2Pics: []
				},
				commonProblemList: [],
				protocolList: [],
				gameList1: [],
				totalNum: 0,
				storeSearch: {
					pageNo: 1,
					pageSize: 200,
					settledType: 5,
					gameId: '',

				},
				noRecommendedList: []

			}
		},
		created() {
			setTimeout(() => {
				console.log(this.$store.state.userInfo)
				if (getToken() != undefined && getToken() != '' && getToken() != null) {
					this.token = getToken()
					userCenter().then(res => {
						if (res.code == 200) {
							this.userInfo = res.result
						}
					})
				} else {
					this.token = undefined
				}
			}, 200)
			// 获取公告列表
			this.toServiceCenter(0)
			this.getGameList()
			this.getGameList1()
			this.getHotList()
			this.getGameBanner()
			this.getOnlineGameList()
			this.getQueryPic()
			this.getGameCommodityList()

		},
		mounted() {
			// ref="dialogBox"
			// 监听全局点击事件
			document.addEventListener('click', (e) => {
				if (e.target.className == 'selectSpan') return
				if (this.$refs.dialogBoxs && !this.$refs.dialogBoxs.contains(e.target)) {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}
			});



		},

		methods: {
			toDetaile(item) {
				this.$router.push('/gameDetaile/' + item.id)
			},
			// 跳转发布页面
			toPubilc2(type) {
				if (Object.keys(this.selectGame1).length == 0) {
					this.message('请先选择游戏', 'error')
					return
				}
				// 判断是否实名
				realInfo().then(res => {
					if (res.code == 200) {
						if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
							this.message('请先完成实名认证', 'error')
							setTimeout(() => {
								this.$router.push('/setting/2')
							}, 1000)
						} else {
							settledStoreList().then(res => {
								if (res.code == 200) {
									const isGameLeveling = res.result?.settled?.filter(item => {
										return item.settledType == 5
									})
									if (isGameLeveling.length > 0) {
										gameCommodityTypes({
											id: this.selectGame1.id,
											name: ''
										}).then(res => {
											res.result.forEach(data => {
												if (data.gameCommodityCategory == type) {
													let info = {
														// 游戏商品分类id	
														commodityTypeId: data.id,
														// 游戏商品分类名称	
														commodityTypeName: data.name,
														gameName: this.selectGame1.name,
														gameCommodityCategory: data.gameCommodityCategory,
														// 游戏ID
														gameId: this.selectGame1.id,
														// 保证金金额
														// deposit: commodityTypeId_dictText.deposit,
														// 游戏图标
														icon: this.selectGame1.icon,
														addType: 'add'
													}
													info.tradeRemark = data.publishRemark
													info.isDelivery = data.isDelivery
													info.minInsure = data.minInsure
													// localStorage.setItem('publish2', JSON.stringify(info));
													this.$router.push({
														path: '/publish2',
														query: {
															info: JSON.stringify(info)
														}
													})
													return
												}
											})
										})
									} else {
										MessageBox.confirm('您还没有申请代练入驻，是否立即申请？', '提示', {
											confirmButtonText: '确定',
											cancelButtonText: '取消',
											type: 'warning'
										}).then(() => {
											this.$router.push('/mineIndex/17')
										}).catch(() => {})
									}
								}
							})
						}
					}
				})


			},

			// 滑动
			scollBottom() {
				setTimeout(() => {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					console.log(osTop)
					console.log(ispeed)
					document.documentElement.scrollTop = document.body.scrollTop = 520;
					this.isTop = true;

				}, 1000)
			},
			chatCenter(event) {
				console.log(event)
			},

			toPublic() {
				if (Object.keys(this.selectGame1).length == 0) {
					this.message('请先选择游戏', 'error')
					return
				}

				// 判断是否实名
				realInfo().then(res => {
					if (res.code == 200) {
						if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
							// this.realShow = true
							this.message('请先完成实名认证', 'error')
							setTimeout(() => {
								this.$router.push('/setting/2')
							}, 1000)
						} else {
							let info = {
								// 游戏商品分类id	
								commodityTypeId: this.gameList1[0].commodityTypeId,
								// 游戏商品分类名称	
								commodityTypeName: this.gameList1[0].commodityTypeId_dictText,

								// 游戏商品大类
								gameName: this.selectGame1.name,
								gameCommodityCategory: this.search.gameCommodityCategory,
								// 游戏ID
								gameId: this.selectGame1.id,
								// 保证金金额
								// deposit: commodityTypeId_dictText.deposit,
								// 游戏图标
								icon: this.selectGame1.icon,
								addType: 'add'
							}


							info.tradeRemark = this.gameList1[0].publishRemark
							info.isDelivery = this.gameList1[0].isDelivery
							info.minInsure = this.gameList1[0].minInsure
							// localStorage.setItem('publish2', JSON.stringify(info));
							this.$router.push({
								path: '/publish2',
								query: {
									info: JSON.stringify(info)
								}
							})
						}
					}
				})



			},
			getNoRecommend() {
				storeAccountList(this.storeSearch).then(res => {
					if (res.code == 200) {
						this.noRecommendedList = res.result.records
					}
				})
			},
			dialogInfo(info) {
				if (info.userId == this.$store.state.userInfo.id) {
					this.message('不能给自己聊天', 'error')
					return
				}

				let infos = {
					consultId: info.userId,
					userId: this.$store.state.userInfo.id,
					type: 3
				}
				// if(this.search.gameCommodityCategory == 2){
				// 	infos.consultId= info.shopId
				// }

				localStorage.setItem('recordType', infos.type)
				sessionRecordAdd(infos).then(res => {
					if (res.code == 200) {
						this.$router.push('/chat/' + infos.consultId)
					}
				})
			},
			toOrderPay(item) {
				// 判断是否实名
				realInfo().then(res => {
					if (res.code == 200) {
						if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
							// this.realShow = true
							this.message('请先完成实名认证', 'error')
							setTimeout(() => {
								this.$router.push('/setting/2')
							}, 1000)
						} else {

							let info = {
								id: item.id,
								num: 1,
								payType: item.payType,
								type: 'pay'
							}
							// localStorage.setItem('confirmOrder', JSON.stringify(info));
							this.$router.push({
								path: '/confirmOrder',
								query: {
									info: JSON.stringify(info)
								}
							})
						}
					}
				})
			},
			getGameCommodityList() {
				listDailian(Object.assign({
					isHot: 0
				}, this.search)).then(res => {
					if (res.code == 200) {
						this.gameList1 = res.result.records
					} else {
						this.gameList1 = []
					}
					this.totalNum = res.result.total

				})
			},
			typeSearch(type) {
				if (type == 5) {
					this.search.orderType = 3
				} else if (type == 3) {
					if (this.search.orderType == 4) {
						this.search.orderType = 5
					} else {
						this.search.orderType = 4
					}
				} else {
					this.search.orderType = type
				}
			},
			toServiceCenter(type) {
				if (type == 0) {
					noticeList({
						pageNo: '1',
						pageSize: '5'
					}).then(res => {
						if (res.code == 200) {
							this.noticeList = res.result.records
						}
					})
				} else if (type == 1) {
					commonProblemList({
						pageNo: '1',
						pageSize: '5'
					}).then(res => {
						if (res.code == 200) {
							this.commonProblemList = res.result.records
						}
					})
				} else if (type == 2) {
					protocolList({
						pageNo: '1',
						pageSize: '5'
					}).then(res => {
						if (res.code == 200) {
							this.protocolList = res.result.records
						}
					})
				}
				this.lableAction = type
			},
			getQueryPic() {
				queryPic().then(res => {
					if (res.code == 200) {
						this.funList = res.result.banner
						this.queryPicList = res.result
						this.queryPicList.f1.terminalType = 1
						this.queryPicList.f2.terminalType = 2
					}
				})
			},
			// 获取网络游戏和手机游戏
			getOnlineGameList() {
				gameList({
					// isHot:0,
					pageNo: 1,
					pageSize: 10,
					terminalType: 1
				}).then(res => {
					if (res.code == 200) {
						this.onlineGameList = res.result.records
					}
				})
				gameList({
					// isHot:0,
					pageNo: 1,
					pageSize: 10,
					terminalType: 2
				}).then(res => {
					if (res.code == 200) {
						this.handGameList = res.result.records
					}
				})
			},
			// 获取轮播图
			getGameBanner() {
				gameBanner().then(res => {
					if (res.code == '200') {
						this.bannerList = res.result
					}
				})
			},
			// 获取热门搜索
			getHotList() {
				hotList({
					pageNo: 1,
					pageSize: 6
				}).then(res => {
					this.hotTagList = res.result.records
				})

			},
			getGameList() {
				gameCommodityCategories({
					pageNo: 1,
					pageSize: 10
				}).then(res => {
					if (res.code == 200) {
						this.hotSort = res.result
					}
				})
			},
			getGameList1() {
				
				gameList(this.gameSearch).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
					}
				})
			},

			// 获取验证码
			getCodes(type, account) {
				if (this.countDown > 0) {
					return
				}
				this.getCode(type, account).then(res => {
					// 开始倒计时
					this.startCountdown();
				})
			},
			startCountdown() {
				this.countDown = 60; // 初始倒计时时间（秒）  
				this.countdownInterval = setInterval(() => {
					this.countDown--;
					if (this.countDown <= 0) {
						clearInterval(this.countdownInterval);
						this.countDown = 0;
						// 可以选择是否自动重新生成验证码  
						// this.generateCode();  
					}
				}, 1000);
			},
			// 切换登录方式
			swiperType(type) {
				console.log(type)
				this.loginType = type
			},

			// 登录
			login() {
				this.shoWData = true

			},
			verifySubmit(e, verSuccess) {
				if (verSuccess) {
					this.shoWData = false
					// 短信登录
					if (this.loginType == 0) {
						this.$refs.loginForm.validate((valid) => {
							if (valid) {
								this.$store.dispatch('loginCode', this.loginForm).then(() => {
									this.headShow = false
									this.message('登陆成功', 'success')
									this.token = getToken()
									userCenter().then(res => {
										if (res.code == 200) {
											this.userInfo = res.result
										}
									})
									this.headShow = true
								})
							}
						})

						// 验证码登录
					} else {
						this.$refs.loginPassForm.validate((valid) => {
							if (valid) {
								this.$store.dispatch('login', this.loginPassForm).then(() => {
									this.headShow = false
									this.message('登陆成功', 'success')
									this.token = getToken()
									userCenter().then(res => {
										if (res.code == 200) {
											this.userInfo = res.result
										}
									})
									this.headShow = true
								})
							}
						})
					}
				}

			},

			loadMore() {
				const lastIndex = this.noticeList.length - 1;
				const lastItem = this.noticeList[lastIndex];
				const nextIndex = lastItem.id + 1;

				setTimeout(() => {
					const newData = this.noticeList.slice(nextIndex - 1, nextIndex + 9).map(item => {
						return {
							id: item.id,
							content: item.content
						};
					});

					this.noticeList = [...this.noticeList, ...newData];
				}, 1000);
			},
			// 选择游戏的时候清空其他数据
			selectGameShow(type) {
				if (type == 'selectGame') {
					this.selectShow = true
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectGameTypeList') {
					this.selectShow = false
					this.selectShow1 = true
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectSonGameTypeList') {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = true
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}

			},
			// 选择游戏
			gameSelect(item) {
				this.selectGame = {
					id: item.id,
					name: item.name,
					terminalType: item.terminalType,
					icon: item.icon
				}
				// 获取商品类型
				gameCommodityTypes({
					id: item.id
				}).then(res => {
					this.gameTypeList = res.result
					this.selectShow = false
					this.selectShow1 = true

					this.selectGameTypeList = {}

					this.sonGameTypeList = []
					this.selectSonGameTypeList = {}

					this.districtList = []
					this.selectDistrict = {}

					this.sonDistrictList = []
					this.sonSelectDistrict = {}

					this.systemList = []
					this.selectSystem = {}
				})
			},
			// 选择游戏类型
			gameTypeSelect(item) {
				this.selectGameTypeList = {
					id: item.id,
					name: item.name,
				}
				this.systemList = []
				this.selectSystem = {}
				// 如果是手游的话先获取系统
				if (this.selectGame.terminalType == 2) {
					if (item.childrenName != null) {
						this.sonGameTypeList = item.childrenName.split(' ')
						// this.sonGameTypeList=['111','2222']
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							this.systemList = res.result
							this.selectShow1 = false
							this.selectShow2 = false
							this.selectShow5 = true
						})
					}
				} else {
					if (item.childrenName != null && item.childrenName != '') {
						this.sonGameTypeList = item.childrenName.split(' ')
						// this.sonGameTypeList=['111','2222']
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							this.districtList = res.result
							this.selectShow2 = false
							this.selectShow1 = false
							this.selectShow3 = true
						})
					}
				}


			},
			// 选择系统
			systemSelect(item) {
				this.selectSystem = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectSystem.id
				}).then(res => {
					this.districtList = res.result
					this.selectShow5 = false
					this.selectShow3 = true
				})
			},
			// 选择商品子类
			sonGameTypeSelect(item) {
				this.selectSonGameTypeList = {
					name: item
				}
				if (this.selectGame.terminalType == 2) {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						this.systemList = res.result
						this.selectShow2 = false
						this.selectShow5 = true
					})
				} else {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						this.districtList = res.result
						this.selectShow2 = false
						this.selectShow3 = true
					})
				}


			},
			// 选择区服
			districtSelect(item) {
				this.selectDistrict = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectDistrict.id
				}).then(res => {
					this.sonDistrictList = res.result
					this.selectShow3 = false
					this.selectShow4 = true
				})
			},
			// 选择大区
			sonDistrictSelect(item) {
				this.sonSelectDistrict = {
					id: item.id,
					name: item.name,
				}
				this.selectShow4 = false
			},
			// 筛选游戏名称
			selectSearch(value, type) {
				if (type == 'type') {
					this.gameSearch.terminalType = value
				}
				if (type == 'initial') {
					if (value == '热门游戏') {
						this.gameSearch.initial = ''
					} else {
						this.gameSearch.initial = value
					}
				}
				this.getGameList1()
			},

			// 鼠标划过事件
			handleMouseEnter(event, index) {
				this.hoverShow = true
				this.hoverIndex = index
			},
			handleMouseLeave() {
				this.hoverShow = false
			},
			// 微信登录
			weixinLogin() {

				// let url = 'http://'+window.location.host+ '/#/login/login'
				let redirect_uri = encodeURIComponent('http://www.cc007.com/gameShop/#/login/login')
				let appid = 'wx34ce80f4fc983974'
				window.location.href =
					`https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=123#wechat_redirect`

			},
			// 跳转到商品列表
			toShopList(item1, item2) {
				let info = {
					id: item2.id,
					terminalType: item2.terminalType,
					name: item2.name,
					icon: item2.icon,
					gameTypeSelect: {
						id: item2.gameTypeId,
						name: item2.gameTypeName
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}

				}).href, '_blank');
			},
			selectGames(item) {
				if (item.id != this.search.gameId) {
					this.search.gameId = item.id
					this.storeSearch.gameId = item.id
					this.selectGame1 = item
					this.search.pageNo = 1
					this.storeSearch.pageNo = 1
					this.getGameCommodityList()
					this.getNoRecommend()
				} else {
					this.search.gameId = ''
					this.storeSearch.gameId = ''
					this.selectGame1 = {}
					this.search.pageNo = 1
					this.storeSearch.pageNo = 1
					this.getGameCommodityList()
					this.getNoRecommend()
				}

			},
			toShopLists(item) {
				let info = {
					id: item.id,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
			toShopLists1(item, item1) {
				let info = {
					id: item.id,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
					gameTypeSelect: {
						id: item1.id,
						name: item1.name
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');
			},
			toShopLists2(item) {
				let info = {
					id: item.gameId,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
			toLink(item) {
				if (!this.isEmpty(item.url) && item.bannerType == 1) {
					window.open(item.url)
				}

			},
			toLink1(item) {
				if (!this.isEmpty(item.url)) {
					window.open(item.url)
				}

			},
			searchShop() {
				if (this.searchKey != '' && /^\d+$/.test(this.searchKey) == true) {
					window.open(this.$router.resolve({
						path: '/gameDetaile/' + this.searchKey
					}).href, '_blank');
					return
				}
				if (Object.keys(this.selectGame).length == 0) {
					this.message('请先选择游戏', 'error')
					return
				}

				let info = {
					id: this.selectGame.id,
					name: this.selectGame.name,
					terminalType: this.selectGame.terminalType,
					icon: this.selectGame.icon,
					title: this.searchKey
				}
				if (Object.keys(this.selectGameTypeList).length != 0) {
					info.gameTypeSelect = {
						id: this.selectGameTypeList.id,
						name: this.selectGameTypeList.name
					}
				}
				if (Object.keys(this.selectSonGameTypeList).length != 0) {
					info.gameTypeSonSelect = {
						name: this.selectSonGameTypeList.name
					}
				}

				if (Object.keys(this.selectDistrict).length != 0) {
					info.selectDistrict = {
						id: this.selectDistrict.id,
						name: this.selectDistrict.name
					}
				}
				if (Object.keys(this.sonSelectDistrict).length != 0) {
					info.sonSelectDistrict = {
						id: this.sonSelectDistrict.id,
						name: this.sonSelectDistrict.name
					}
				}
				if (Object.keys(this.selectSystem).length != 0) {
					info.selectSystem = {
						id: this.selectSystem.id,
						name: this.selectSystem.name
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			}

		}

	}
</script>

<style lang="scss" scoped>
	::v-deep .el-card {
		border-radius: 10px;
		border: none;
	}

	::v-deep .el-card__body,
	.el-main {
		padding: 0;
	}

	// ::v-deep .el-checkbox__inner {
	// 	width: 20px;
	// 	height: 20px;
	// }

	// ::v-deep .el-input-group{
	// 	border: 1px solid #DEDEDE;
	// }

	.search {
		width: 100%;
		height: 50px;
		background-color: #0085FE;
		border-radius: 5px;
		padding: 4px;
		display: flex;
		align-items: center;
		margin-top: 27px;
		justify-content: space-between;
	}

	.submitSearch {
		width: 120px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		color: #fff;
		font-weight: 600;
		font-size: 20px;
		letter-spacing: 5px;
	}

	.el-dropdown-link {
		font-size: 18px;
		color: #000;
	}

	.downList {
		display: flex;
		align-items: center;
		background-color: #fff;
		height: 50px;
		// margin-left: 2px;
		// margin-right: 2px;

		width: calc(100% - 120px);
		border-radius: 5px;
		position: relative;
	}

	.dialogBox {
		position: absolute;
		top: 60px;
		width: 1400px;
		background-color: #fff;
		z-index: 99999 !important;
		border-radius: 5px;
		padding: 20px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	}

	.downItem {
		width: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	::v-deep .el-input__inner {
		height: 50px;
	}

	.hotList {
		color: #A5A7B1;
		display: flex;
		font-size: 16px;
		margin-top: 10px;
		align-items: center;
		white-space: nowrap;

		.hotItem {
			margin-right: 10px;
			border-radius: 5px;
			border: 1px solid #ECECEC;
			padding: 2px 8px;
			font-size: 16px;
			white-space: nowrap;
		}

		.hotItem:hover {
			background-color: #0085FE;
			color: #fff;
			border: none;
		}
	}

	.sort {
		background-color: #fff;
		height: 545px;
		// overflow: hidden;
		// width: 100%;
		border-radius: 5px;
		position: relative;
		// overflow-y: auto;
		// overflow-x: hidden;

		.hoverList {
			position: absolute;
			right: -808px;
			top: 55px;
			background-color: #fff;
			width: 800px;
			height: 484px;
			z-index: 999;
			border: 4px solid #0085FE;

			.hoverTitle {
				font-size: 26px;
				font-weight: 600;
				padding: 20px 30px;
			}

			.hoverTag {
				padding: 20px 40px;
				display: flex;
				flex-wrap: wrap;

				.tagItem {
					width: 16%;
					margin-bottom: 30px;
					margin-right: 5%;
					font-weight: bold;
					color: #888;
				}

				.tagItem:nth-child(5n) {
					margin-right: 0;
				}

				.tagItem:hover {
					color: #0085FE;
				}

			}
		}


		.sortTop {
			color: #fff;
			height: 35px;
			background-color: #0085FE;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;

			.topLeft {
				font-weight: bold;
				font-size: 20px;
				letter-spacing: 2px;
			}

			.topRight {
				font-size: 14px;
				opacity: 0.8;
			}
		}

		.sortList {
			height: 484px;
			overflow-y: auto;
			scrollbar-width: none;
		}

		.sortItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			border-bottom: 1px solid #F6F6F6;

			.sortTitle {
				font-size: 20px;
				font-weight: 600;
			}

			.sortTag {
				color: #888;
				font-size: 12px;
				font-weight: 600;
				margin-right: 6px;
				white-space: nowrap;
				display: inline-block;
				max-width: 70px;
			}

			.sortTag:hover {
				color: #0085FE;
			}

			.sortTitle:hover {
				color: #0085FE;
			}
		}

	}

	.centerTop {
		color: #000;
		height: 35px;
		// width: 300px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		display: flex;
		// justify-content: space-between;
		// align-items: center;
		padding: 10px;
		font-weight: bold;
		font-size: 20px;

		.centerItem {
			margin-right: 30px;
		}

		.centerItem:hover {
			color: #0085FE;
		}

	}

	.loginText {
		display: flex;
		align-items: center;
		font-size: 24px;
		color: #A5A7B1;
		margin-bottom: 20px;
		margin-top: 10px;

		.login {

			margin-right: 20px;
			font-weight: 500;
		}

		.login1 {
			padding-left: 20px;
			border-left: 3px solid #ECECEC;
		}

		.actionLogin {
			color: #0085FF;
		}
	}

	.feature {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #777A8A;
		font-size: 16px;
		margin-top: -15px;

		.feature2 {
			color: #0085FF;
		}
	}

	.agreement {
		margin-top: 10px;
		font-size: 16px;
		display: flex;
	}

	.functionImage img {
		border-radius: 5px;
		width: 303px;
		height: 191px;
		transition: all 0.5s; //控制速度，也可不加
	}

	.functionImage img:hover {
		transform: scale(1.05); //放大倍数
	}

	.functions {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;



		.function {
			width: 403px;
			height: 291px;
			background-color: #fff;
			border-radius: 5px;
			transition: all 0.5s; //控制速度，也可不加
			overflow: hidden;

			.lables {
				padding: 16px;
				border-bottom: 1px solid #ECECEC;
			}

			.lable {
				font-weight: 600;
				font-size: 18px;


				margin-right: 15px;

			}

			.lableAction {
				color: #0085FF;
				position: relative;
			}

			.lableAction::after {
				content: "";
				position: absolute;
				height: 2px;
				width: 70px;
				bottom: -16px;
				left: 0px;
				background-color: #0085FE;
			}

			.noticeList {
				padding: 16px;
				overflow-y: scroll;
				height: 191px;

				.noticeItem {
					display: flex;
					justify-content: space-between;
					margin-bottom: 18px;
				}

				.noticeItem:hover {
					color: #0085FF;
				}
			}

			.noticeList::-webkit-scrollbar {
				display: none;
			}
		}

		.function:hover {
			transform: scale(1.05); //放大倍数
		}
	}

	.title {
		text-align: center;
		margin-top: 46px;
		margin-bottom: 46px;

		.titleH1 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 32px;
			color: #1D223C;
			line-height: 42px;

		}

		.titleH2 {

			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			line-height: 21px;
			margin-top: 8px;

		}
	}

	.gameImage img {
		border-radius: 5px;
		width: 317px;
		height: 460px;
		transition: all 0.5s; //控制速度，也可不加
	}

	.gameImage img:hover {
		transform: scale(1.04); //放大倍数
	}

	::v-deep .el-carousel__indicators--outside button {
		background-color: #0085FF;
		height: 4px;
		border-radius: 10px;
	}

	.game {

		display: flex;
		justify-content: space-between;
		// width: 1920px;
		// height: 536px;
		// align-items: center;

		.gameLeft {
			// width: 320px;
			height: 536px;

		}

		.gameRight {
			display: flex;
			flex-wrap: wrap;
			// align-items: center;
			// justify-content: space-between;
			margin-left: 20px;
			// height: 400px;
			width: 100%;

			.rightItem {
				width: 134px;
				height: 134px;
				// margin-right: 12px;
				background: #FFFFFF;
				border-radius: 12px 12px 12px 12px;
				// border: 1px solid #ECECEC;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: 18px;
				cursor: pointer;

				.rightItem img {
					width: 50px;
					height: 50px;
					border-radius: 50px;
				}

				.itemName {
					margin: 12px;
					font-weight: bold;
					font-size: 14px;
					width: 140px;
					text-align: center;
				}

				.itemTag {
					font-weight: 400;
					font-size: 14px;
					color: #A5A7B1;

					.itemTags:hover {
						color: #FF5B01;
					}

				}
			}

			// .rightItem:hover {
			// 	background: linear-gradient(180deg, #03B5FF 0%, #0085FF 100%);

			// }

			// .rightItem:hover div {
			// 	color: #fff;

			// }
		}
	}

	.showMore {
		border: 1px solid #0085FF;
		color: #0085FF;
		font-size: 20px;
		width: 188px;
		height: 59px;
		border-radius: 8px 8px 8px 8px;
		text-align: center;
		line-height: 59px;
		margin: 10px auto;

	}

	.showMore:hover {
		background-color: rgba(102, 177, 255, .2);
		// color: #fff;
	}

	.sellItem {
		img {
			transition: all 0.5s; //控制速度，也可不加
		}

		img:hover {
			transform: scale(1.04); //放大倍数
		}

		.sellTitle {
			padding: 20px;
			display: flex;
			justify-content: space-between;
			font-size: 18px;
			font-weight: bold;
			border-bottom: 1px solid #ECECEC;
		}

		.sellPrice {
			padding: 20px;
			font-weight: bold;
			font-size: 20px;
			color: #FF5C00;
			line-height: 28px;

		}
	}

	.getCode {
		position: absolute;
		top: 7px;
		right: 5px;
		// line-height: 80px;
		font-size: 14px;
		color: #0085FF;
	}

	.getCode::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: -10px;
		top: 10px;
		background-color: #ECECEC;
	}

	.userInfo {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;

		.infoImage {
			width: 72px;
			height: 72px;
			border-radius: 50%;
		}

		.userName {
			font-weight: 400;
			font-size: 18px;
			margin-top: 12px;
		}

		.userList {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.userItem {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 50%;
				margin-top: 15px;

				.userNumber {
					font-weight: bold;
					font-size: 24px;
					color: #1D223C;
				}

				.userText {
					font-weight: 400;
					font-size: 18px;
					color: #777A8A;
					margin-top: 6px;
				}

				.userBtn {
					margin-top: 24px;
					width: 110px;
					height: 48px;
					background: #0085FF;
					border-radius: 8px 8px 8px 8px;
					font-weight: 600;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 48px;
					text-align: center;

				}

				.userBtn1 {
					margin-top: 24px;
					width: 110px;
					height: 48px;
					background: #00E0B5;
					border-radius: 8px 8px 8px 8px;
					font-weight: 600;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 48px;
					text-align: center;
				}
			}
		}
	}

	.equityList {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;

		.equityItem {
			width: 30%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 24px;
		}
	}

	.searchItem {
		min-width: 100px;
		text-align: center;
		white-space: nowrap;
	}



	.searchName {
		padding: 0 20px;

		.searchLable {
			color: #000;
			opacity: 0.8;
			font-size: 16px;
		}

		.searchTag {
			font-size: 16px;
			margin-left: 10px;
		}
	}

	.typeTop {
		height: 40px;
		background-color: #FAFAFA;
		justify-content: space-between;

		::v-deep .el-input__inner {
			height: 40px;
		}

		.gameType {
			width: 150px;
			font-size: 18px;
			line-height: 40px;
			text-align: center;
		}

		.gameTypeAction {
			background-color: #0085FE;
			color: #fff;
		}
	}

	.selectList {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;

		.selectItem {
			width: 190px;
			margin-bottom: 20px;
			// color: #0085FE;
			font-size: 16px;
		}

		.selectSpan:hover {
			background-color: #0085FE;
			color: #fff;
		}

	}

	.initialList {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;

		.initialItem {
			font-size: 18px;

		}
	}


	/* 公告card */
	.TopCard .el-card__body {
		padding: 0 10px
	}

	.showNotice {
		font-weight: 500;
		font-size: 18px;
		color: red;
	}

	.TopCard {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 92px;
		// width: 2000px;
	}

	.floor {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.floorLeft {
			font-weight: bold;
			display: flex;
			font-size: 22px;


		}

		.floorRight {
			display: flex;
			align-items: center;
			// margin-right: 80px;
			color: #666;
		}

		.floorRight:hover {
			color: #FF5B01;
		}
	}

	// /* 主内容宽度 */
	// .content {
	// 	margin: 0 auto;
	// 	width: 1920px;

	// 	font-family: 'PingFangSC';
	// }

	// /* 宽度低于1920，给主体内容左右加留白 */
	// @media screen and (max-width: 1920px) {
	// 	.content {
	// 		overflow: hidden;
	// 		overflow-x: auto;
	// 	}
	// }
	.publish {
		display: flex;
		flex-direction: column;
		width: 164px;
		height: 48px;
		background: #FF5C00;
		border-radius: 4px 4px 4px 4px;
		align-items: center;
		justify-content: center;
		color: #fff;
		cursor: pointer;
	}

	.sortList1 {
		height: 33px;
		// background: #FFFFFF;
		border-radius: 4px;
		// border: 1px solid #ECECEC;
		overflow: hidden;

		.sortItem1 {
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
			height: 33px;
			line-height: 33px;
			padding: 0 8px;
			// border-right: 1px solid #ECECEC;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

		}

		.sortItem1:nth-child(3) {
			border: none;
		}

		.sortActin {
			color: #0085FF;

		}
	}

	.classity {
		font-size: 16px;
		margin-right: 30px;
		padding: 8px 5px;
		border-radius: 5px;
		cursor: pointer;
	}

	.classityAct {
		color: #0085FF;
		background-color: rgba(0, 133, 254, .4);
	}

	.screenItem {
		color: #A5A7B1;
		align-items: center;
		min-height: 64px;
		background: #F7F7F7;

		.screenLable {
			width: 140px;
			text-align: center;
			min-height: 64px;
			// line-height: 64px;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.screenOptions {
			margin-left: 20px;
			padding: 10px 8px;
			width: 100%;
			min-height: 64px;
			background: #fff;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			// overflow-x: auto;

		}

	}

	.guarantee {
		padding: 20px;
		width: 100%;
		font-size: 18px;
		background: #FFFFFF;
		border-bottom: 1px solid #ececec;
		align-items: center;
	}

	.infoTitle {
		font-weight: 600;
		font-size: 16px;
		// line-height: 30px;
		color: #000000;
		width: 100%;
		position: relative;

		.infoAccount {
			font-size: 16px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}

		.infoImage {
			font-size: 16px;
			background: #0085FF;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
			margin-left: 5px;
		}

		.hoverDiv {
			// display: none;
			// position: absolute;
			// right: -10px;
		}
	}

	.infoTitle:hover {
		color: #FF5C00;

		.hoverDiv {
			display: block;
			// position: absolute;
			// right: -10px;
		}
	}

	.infoSpan {
		line-height: 34px;

		.spanItem {
			height: 32px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			background: #F5F5F5;
			border-radius: 2px 2px 2px 2px;
			padding: 5px 4px;
			margin-right: 10px;
		}
	}

	.serverSide {
		font-size: 16px;
		color: #FFE350;
		line-height: 19px;
		background-color: #1B2948;
		padding: 2px 4px;
		border-radius: 2px;
		position: relative;
		margin-left: 10px;

	}

	.serverSide::before {
		content: "";
		position: absolute;
		top: 8px;
		left: -4px;
		transform: rotate(-45deg);
		border-top: 10px solid #1B2948;
		/* 三角形的高度和颜色 */
		border-right: 10px solid transparent;
		/* 三角形的宽度 */
	}


	.area {
		font-weight: 400;
		font-size: 12px;
		color: #777A8A;
		margin-top: 10px;
	}

	.accountPrice {
		color: #FF5C00;
		font-weight: bold;
		font-size: 28px;
		width: 25%;
		text-align: center;
	}

	.accountNumber {
		color: #000;
		font-weight: bold;
		font-size: 28px;
		text-align: center;
		width: 25%;
	}

	.accountNumber1 {
		color: #000;
		font-weight: bold;
		font-size: 28px;
		width: 15%;
	}

	.accountService {
		width: 15%;
		margin: 0 auto;
		font-size: 18px;
		color: #1D223C;
	}

	.accountPay {
		display: flex;

		.accountDetaile {
			width: 148px;
			height: 38px;
			background: #FFDB51;
			border-radius: 40px;
			// border: 1px solid #FF5C00;
			font-size: 16px;
			color: #333;
			text-align: center;
			line-height: 38px;
			cursor: pointer;
		}

		.accountDetaile1 {
			width: 148px;
			height: 38px;
			// background: #FFDB51;
			border-radius: 40px;
			border: 1px solid #333;
			font-size: 16px;
			color: #333;
			text-align: center;
			line-height: 38px;
			margin-top: 10px;
			cursor: pointer;
		}

		// .accountDetaile:hover {
		// 	background-color: #FF5C00;
		// 	color: #fff;
		// }

		.addCard {
			width: 24px;
			height: 24px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			padding: 10px;
			margin-left: 10px;
			cursor: pointer;
		}
	}

	.floorLeft {
		font-weight: bold;
		display: flex;
		font-size: 18px;
		margin: 10px 20px;
	}

	.selectAction {
		border: 3px solid #0085FF;

	}
</style>