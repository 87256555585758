<template>
	<div>
		<div style="background: #03B5FF;height: 100px;">
			<el-row class="content" style="background: #03B5FF;height: 100px;">
				<el-col :span="24">
					<div class="funcList ">
						<div class="funcItem" v-for="(item,index) in funcList" :key="index">
							<div class="list">
								<img style="width: 60px;height: 60px;" :src="item.image" />
							</div>
							<div>
								<div class="itemTitle">{{item.title}}</div>
								<div class="itemDetaile">{{item.detaile}}</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div style="background: #F8F8F8;height: 483px;">

			<el-row class="content" style="background: #F8F8F8;height: 483px;">

				<el-col :span="24">
					<div
						style="display: flex;justify-content: space-between;border-bottom: 1px solid rgba(29, 34, 60, 0.1);align-items: center;">
						<div>
							<img style="width: 156px;" :src="require('@/static/image/newImage/logo.png')" alt="" />
						</div>
						<div class="helpList">
							<div class="helpItem" v-for="(item,index) in bottomList" :key="index"
								@click="navTo(item.router)">
								<div class="helpTitle">{{item.title}}</div>
								<div class="helpChild" v-for="(items,indexs) in item.child" :key="indexs">{{items}}
								</div>
							</div>

						</div>
						<div class="helpRight" @click="navTo(0)">
							<div class="rightText">客服电话</div>
							<div class="rightPhone">0371-88916455</div>
							<div class="rightDetaile">24h人工专属热线</div>
							<div class="rightConsult">
								<img style="width: 25px;height: 25px;margin-right: 10px;"
									:src="require('@/static/image/index/Frame.png')" alt="" />
								咨询客服
							</div>
						</div>
					</div>
					<div style="display: flex;align-items:center;flex-direction: column;">
						<div class="bottomList">
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/aboutUs')">关于我们</el-link>
							<el-link :underline="false" class="interval">|</el-link>
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/serviceCenter/0/0')">联系我们</el-link>
							<el-link :underline="false" class="interval">|</el-link>
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/serviceCenter/0/0')">投诉建议</el-link>
							<el-link :underline="false" class="interval">|</el-link>

							<el-link :underline="false" class="bottomText" @click="showViewer = true">营业资质</el-link>
							<el-link :underline="false" class="interval">|</el-link>
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/serviceCenter/0/0')">免责声明</el-link>
						</div>
						
						<div  class="filing fl" style="cursor: default;">APP名称:有游有品|版本:V1.0 |开发者：郑州市启生网络技术有限公司  产品功能:提供网络游戏道具代练等服务
						
						</div>
						<div @click="openLink1" class="filing fl">Copyright © 2024 郑州市启生网络技术有限公司版权所有 ICP证：豫ICP备2024054473号
							豫公网安备41019702003580号

							<img @click="openLink" :src="require('@/static/image/index/link1.png')"
								style="width:18px;margin-left: 10px;margin-top: -5px;cursor:pointer;" />
						</div>
					</div>

				</el-col>
				
			</el-row>
		</div>
		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="['http://www.cc007.com/media/image.jpg']"
			index="9999" />
	</div>
</template>

<script>
	// 导入组件
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		EventBus
	} from '@/static/js/event-bus.js';

	export default {
		name: 'FooterBar',
		components: {
			ElImageViewer
		},
		data() {
			return {
				showViewer: false,
				funcList: [{
						image: require('@/static/image/index/function5.png'),
						title: '安全快捷注册',
						detaile: '轻松发布 高额盈利'
					},
					{
						image: require('@/static/image/index/function6.png'),
						title: '一键发货',
						detaile: '轻松发布 高额盈利'
					},
					{
						image: require('@/static/image/index/function7.png'),
						title: '24H专属客服',
						detaile: '轻松发布 高额盈利'
					},
					{
						image: require('@/static/image/index/function8.png'),
						title: '资金保障',
						detaile: '保障资金交易安全'
					}
				],
				bottomList: [{
						title: '买家帮助',
						router: '/serviceCenter/2/0',
						child: [
							'挑选商品',
							'订单支付',
							'发货进度',
							'确认收货'
						]
					},
					{
						title: '卖家帮助',
						router: '/serviceCenter/2/0',
						child: [
							'商家服务',
							'发布商品',
							'如何发货',
							'保障图标'
						]
					},
					{
						title: '服务中心',
						router: '0',
						child: [
							'我要咨询',
							'我要建议',
							'我要投诉',
							'真假客服'
						]
					},
					{
						title: '隐私政策',
						router: '/serviceCenter/3/0',
						child: [
							'免责声明',
							'用户协议',
							'交易协议',
							'注册协议'
						]
					}
				],

			}
		},
		methods: {
			closeViewer() {
				this.showViewer = false
			},
			openLink1() {
				window.open('https://beian.miit.gov.cn/#/Integrated/recordQuery')
			},
			openLink() {
				window.open('https://beian.mps.gov.cn/')
			},
			navTo(router) {
				if (router == '0') {
					this.$confirm('是否需要联系在线客服？')
						.then(_ => {
							
							EventBus.$emit('sericeCard', 'true');

						})
						.catch(_ => {});
				} else {
					this.$router.push(router)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.funcList {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100px;
		// width: 1920px;
		margin: 0 auto;

		.funcItem {
			display: flex;
			align-items: center;

			.list {
				margin-right: 24px;
			}

			.itemTitle {
				font-weight: 500;
				font-size: 18px;
				color: #FFFFFF;
			}

			.itemDetaile {
				font-size: 14px;
				color: #FFFFFF;

			}
		}
	}

	.helpList {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 300px;
		width: 100%;


		.helpItem {
			display: flex;
			flex-direction: column;
			margin-right: 120px;

			.helpTitle {
				font-weight: 500;
				font-size: 16px;
				color: #1D223C;
				margin-bottom: 20px;
				cursor: pointer;
			}

			.helpTitle:hover {
				color: #04B5FF;


			}

			.helpChild {
				font-weight: 400;
				font-size: 14px;
				color: #1D223C;
				margin-bottom: 20px;
				cursor: pointer;
			}

			.helpChild:hover {
				color: #04B5FF;
			}
		}

		.helpItem:last-child {
			margin-right: 0;
		}
	}

	.helpRight {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		height: 350px;
		width: 30%;
		position: relative;

		.rightText {
			font-weight: 500;
			font-size: 16px;
			color: #1D223C;
			line-height: 28px;

		}



		.rightPhone {
			font-weight: 500;
			font-size: 24px;
			color: #1D223C;
			padding: 20px 0;
		}


		.rightDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}


		.rightConsult {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #1D223C;
			width: 108px;
			font-size: 14px;
			height: 31px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #E5E5E5;
			margin: 20px auto;
			cursor: pointer;
		}

		.rightConsult:hover {
			color: #04B5FF;
		}
	}

	.helpRight::before {
		position: absolute;
		content: '';
		width: 1px;
		height: 128px;
		opacity: 0.12;
		background-color: #fff;
		left: 0;
		top: 130px;
	}

	.bottomList {
		font-size: 14px;
		color: #1D223C;

		text-align: center;
		margin-top: 20px;

		.interval {
			margin: 0 20px;
		}

		.bottomText:hover {
			color: #04B5FF;
		}

	}

	.filing {
cursor: pointer;
		font-size: 14px;
		color: #0085FF;
		line-height: 19px;
		// opacity: 0.4;
		text-align: center;
		margin-top: 14px;
	}
</style>