<template>
	<div>
		

		<div style="background: #fff;height: 163px;">

			<el-row class="content" style="background: #fff;height: 163px;">

				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
				<el-col :span="20">
					
					<div style="display: flex;align-items:center;flex-direction: column;">
						<div class="bottomList">
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/aboutUs')">关于我们</el-link>
							<el-link :underline="false" class="interval">|</el-link>
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/serviceCenter/0/0')">联系我们</el-link>
							<el-link :underline="false" class="interval">|</el-link>
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/serviceCenter/0/0')">投诉建议</el-link>
							<el-link :underline="false" class="interval">|</el-link>

							<el-link :underline="false" class="bottomText" @click="showViewer = true">营业资质</el-link>
							<el-link :underline="false" class="interval">|</el-link>
							<el-link :underline="false" class="bottomText"
								@click="$router.push('/serviceCenter/0/0')">免责声明</el-link>
						</div>
						<div class="filing fl">APP名称:有游有品|版本:V1.0 |开发者：郑州市启生网络技术有限公司 产品功能:提供网络游戏道具代练等服务
						
							
						</div>
						<div class="filing fl">Copyright © 2024 郑州市启生网络技术有限公司版权所有 ICP证：豫B2-20240154
							豫公网安备41019702003580号

							<img @click="openLink" :src="require('@/static/image/index/link1.png')"
								style="width:18px;margin-left: 10px;margin-top: -5px;cursor:pointer;" />
						</div>
					</div>

				</el-col>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
			</el-row>
		</div>
		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="['http://www.cc007.com/media/image.jpg']"
			index="9999" />
	</div>
</template>

<script>
	// 导入组件
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		EventBus
	} from '@/static/js/event-bus.js';

	export default {
		name: 'FooterBar',
		components: {
			ElImageViewer
		},
		data() {
			return {
				showViewer: false,
				funcList: [{
						image: require('@/static/image/index/function5.png'),
						title: '安全快捷注册',
						detaile: '轻松发布 高额盈利'
					},
					{
						image: require('@/static/image/index/function6.png'),
						title: '一键发货',
						detaile: '轻松发布 高额盈利'
					},
					{
						image: require('@/static/image/index/function7.png'),
						title: '24H专属客服',
						detaile: '轻松发布 高额盈利'
					},
					{
						image: require('@/static/image/index/function8.png'),
						title: '资金保障',
						detaile: '保障资金交易安全'
					}
				],
				bottomList: [{
						title: '买家帮助',
						router: '/serviceCenter/0/0',
						child: [
							'挑选商品',
							'订单支付',
							'发货进度',
							'确认收货'
						]
					},
					{
						title: '卖家帮助',
						router: '/serviceCenter/0/0',
						child: [
							'商家服务',
							'发布商品',
							'如何发货',
							'保障图标'
						]
					},
					{
						title: '服务中心',
						router: '0',
						child: [
							'我要咨询',
							'我要建议',
							'我要投诉',
							'真假客服'
						]
					},
					{
						title: '隐私政策',
						router: '/serviceCenter/3/0',
						child: [
							'免责声明',
							'用户协议',
							'交易协议',
							'注册协议'
						]
					}
				],

			}
		},
		methods: {
			closeViewer() {
				this.showViewer = false
			},
			openLink() {
				window.open('https://beian.mps.gov.cn/')
			},
			navTo(router) {
				if (router == '0') {
					this.$confirm('是否需要联系在线客服？')
						.then(_ => {
							// done();
							EventBus.$emit('sericeCard', 'true');

						})
						.catch(_ => {});
				} else {
					this.$router.push(router)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.funcList {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 208px;
		// width: 1920px;
		margin: 0 auto;

		.funcItem {
			display: flex;
			align-items: center;

			.list {
				margin-right: 24px;
			}

			.itemTitle {
				font-weight: 500;
				font-size: 20px;
				color: #FFFFFF;
			}

			.itemDetaile {
				font-size: 14px;
				color: #FFFFFF;

			}
		}
	}
	::v-deep .el-link.el-link--default{
		color:#000;
	}

	.helpList {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 389px;
		width: 100%;


		.helpItem {
			display: flex;
			flex-direction: column;
			margin-right: 120px;

			.helpTitle {
				font-weight: 500;
				font-size: 24px;
				color: #FFFFFF;
				margin-bottom: 20px;
				cursor: pointer;
			}

			.helpTitle:hover {
				color: #04B5FF;


			}

			.helpChild {
				font-weight: 400;
				font-size: 20px;
				color: #FFFFFF;
				margin-bottom: 20px;
				cursor: pointer;
			}

			.helpChild:hover {
				color: #04B5FF;
			}
		}

		.helpItem:last-child {
			margin-right: 0;
		}
	}

	.helpRight {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		height: 389px;
		width: 30%;
		position: relative;

		.rightText {
			font-weight: 500;
			font-size: 24px;
			color: #04B5FF;
			line-height: 28px;

		}



		.rightPhone {
			font-weight: 500;
			font-size: 36px;
			color: #FFFFFF;
			padding: 20px 0;
		}


		.rightDetaile {
			font-weight: 400;
			font-size: 20px;
			color: #FFFFFF;
		}


		.rightConsult {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #A5A7B1;
			width: 128px;
			height: 41px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #A5A7B1;
			margin: 20px auto;
			cursor: pointer;
		}

		.rightConsult:hover {
			color: #04B5FF;
		}
	}

	.helpRight::before {
		position: absolute;
		content: '';
		width: 1px;
		height: 128px;
		opacity: 0.12;
		background-color: #fff;
		left: 0;
		top: 130px;
	}

	.bottomList {
		font-size: 14px;
		color: #FFFFFF;
		text-align: center;
		margin-top: 30px;

		.interval {
			margin: 0 20px;
		}

		.bottomText:hover {
			color: #04B5FF;
		}

	}

	.filing {

		font-size: 14px;
		color: #000;
		line-height: 19px;
		// opacity: 0.4;
		text-align: center;
		margin-top: 24px;
	}
</style>