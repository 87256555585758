<template>
	<div>
		<div v-if="state == 0">
			<div class="roleTitle">提现账户
				<span class="balance">｜账户余额：<span style="color: #0085FF;">{{userInfo.earningsBalance}}</span>元</span>
			</div>

			<div class="state1">
				<div class="noAccount fl">
					<div class="accountLeft">
						<span class="account1">您未添加提现账户</span>
						<span class="account2">您添加的提现账户名必须与实名认证姓名保持一致，否则会造成提现失败</span>
						<div class="toAdd" @click="state = 1">
							<img :src="require('@/static/image/mine/icon1.png')"
								style="width: 24px;height: 24px;margin-right: 8px;" />
							<span>添加提现账户</span>
						</div>
					</div>
					<img :src="require('@/static/image/mine/noAdd.png')"
						style="width: 100px;height: 100px;margin-right: 80px;" />
				</div>
			</div>
			<div class="remind">
				<div class="remind1">温馨提示：</div>
				<div class="remind2">1、提现信息需和实名信息一致</div>
				<div class="remind2">2、提现金额规则提示文本内容</div>
				<div class="remind2">3、提现会收取一定的手续费，具体查看上方收费标准</div>
				<div class="remind2">4、提现金额规则提示文本内容</div>
				<div class="remind2">5、提现会收取一定的手续费，具体查看上方收费标准</div>
			</div>
		</div>

		<div v-if="state == 1">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>绑定提现账户</span>
				<div class="fl" @click="state = 2">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>
			<div class="submitForm">
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">提现类型：</span>
					</div>
					<div class="submitValue typeList">
						<!-- <div class="withType" @click="addForm.bankType = 1" :class="{'action' : addForm.bankType == 1}">
							<span>银行卡</span>
							<img v-if="addForm.bankType == 1" class="actionImage"
								:src="require('@/static/image/mine/action.png')" style="width: 20px;height: 20px;" />
						</div> -->
						<div class="withType" @click="addForm.bankType = 2" :class="{'action' : addForm.bankType == 2}">
							<span>支付宝</span>
							<img v-if="addForm.bankType == 2" class="actionImage"
								:src="require('@/static/image/mine/action.png')" style="width: 20px;height: 20px;" />
						</div>
					</div>

				</div>

				<div v-if="addForm.bankType == 1" class=" formItem" style="display: flex;">
					<div class="submitLable">
						<span class="lableText">扫码绑定：</span>
					</div>
					<div class="submitValue">
						<img :src="addImage" style="width: 272px;height: 272px;" />
					</div>

				</div>
				<div v-if="addForm.bankType == 2" class="fl formItem">
					<div class="submitLable">
						<span class="lableText">开户人名：</span>
					</div>
					<div class="submitValue">
						<!-- <el-input v-prevent-space v-model="addForm.name" disabled placeholder="请输入您的开户人名"></el-input> -->
						<span>{{realName || '请先实名'}}</span>
					</div>
					<!-- <div class="submitRemark">开户人名必须与支付宝开户人一致</div> -->
				</div>

				<div v-if="addForm.bankType == 2" class="fl formItem">
					<div class="submitLable">
						<span class="lableText">支付宝号：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="addForm.phone" placeholder="请输入您的支付宝号"></el-input>
					</div>
				</div>
				<div v-if="addForm.bankType == 2" class="fl formItem">
					<div class="submitLable">
						<span class="lableText">再次确定：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-prevent-space v-model="addForm.againPhone"
							placeholder="请输入您的支付宝号"></el-input>
					</div>
				</div>
				<!-- <div v-if="addForm.bankType == 2" class="fl formItem">
					<div class="submitLable">
						<span class="lableText">已绑定手机：</span>
					</div>
					<div class="submitValue lablePhone">{{phoneEncryption(userInfo.account)}}</div>
				</div> -->
				<!-- <div v-if="addForm.bankType == 2" class="fl formItem">
					<div class="submitLable">
						<span class="lableText">短信验证码：</span>
					</div>
					<div class="submitValue">
						<el-input v-model="addForm.code" type="number" placeholder="请输入验证码">
						</el-input>
						<span @click="getCode('6',userInfo.account)" class="getCode">{{ countDown || '获取验证码'}}</span>
					</div>
				</div> -->
				<div v-if="addForm.bankType == 2" class="fl formItem">
					<div class="submitLable">&emsp;</div>
					<div class="submitValue">
						<el-button type="primary" @click="accountAdd">提交绑定</el-button>
					</div>
				</div>
			</div>
		</div>


		<div v-if="state == 2">
			<div class="roleTitle">提现账户
				<span class="balance">｜账户余额：<span style="color: #0085FF;">{{userInfo.earningsBalance}}</span>元</span>
			</div>
			<div style="overflow: auto;height: 700px;">
				<div class="state1" v-for="(item,index) in accountList">
					<div class="noAccount fl">
						<div class="accountLeft">
							<div class="account1 fl">您已绑定
								<span v-if="item.type == 2">支付宝</span>
								<span v-if="item.type == 1">银行卡</span>
								<div class="delete " @click="deleteShow(item)">
									<img :src="require('@/static/image/mine/delete.png')"
										style="width: 20px;height: 20px;" />
									<span>删除</span>
								</div>
							</div>
							<span class="account2">真实姓名：{{nameEncryption(item.name)}}</span>
							<span v-if="item.type == 2" class="account2">支付宝号：{{phoneEncryption(item.phone)}}</span>
							<span v-if="item.type == 1" class="account2">银行卡号：{{idCardEncryption(item.card)}}</span>
							<span v-if="item.type == 1" class="account2">开户行：{{item.bank}}</span>
							<span class="account2">绑定时间：{{item.createTime}}</span>
						</div>
						<img :src="require('@/static/image/mine/add.png')"
							style="width: 100px;height: 100px;margin-right: 80px;" />
					</div>

				</div>
				<div class="fl">
					<div class="toAdd1" @click="state = 3">
						<img :src="require('@/static/image/mine/icon2.png')"
							style="width: 14px;height: 14px;margin-right: 8px;" />
						<span>我要立即提现</span>
					</div>
					<div class="toAdd" @click="state = 1">
						<img :src="require('@/static/image/mine/icon1.png')"
							style="width: 14px;height: 14px;margin-right: 8px;" />
						<span>添加提现账户</span>
					</div>
				</div>
			</div>

		</div>




		<div v-if="state == 3">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>我要提现</span>
				<div class="fl" @click="state = 2">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>
			<div style="margin-top: 68px;">
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">可提现余额：</span>
					</div>
					<div class="lableNumber">￥{{userInfo.earningsBalance}}</div>
					<div class="submitRemark" @click="$router.push('/serviceCenter/2/0')" style="color: #0085FF;">查看收费标准</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">提现金额：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="withdrawForm.price" type="number"
							placeholder="请输入提现金额(单笔最少提现3元)"></el-input>
					</div>
					<div @click="withdrawForm.price = userInfo.earningsBalance" class="submitRemark"
						style="color: #0085FF;cursor:pointer;">全部提现</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">选择提现账户：</span>
					</div>
					<el-select class="submitValue" v-model="withdrawForm.accountId" placeholder="请选择您要提现的账户">
						<el-option v-for="item in withAccount" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<!-- <div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">开户人名：</span>
					</div>
					<div class="submitValue lablePhone">**轩</div>
				</div> -->
				<div class="fl formItem">
					<div class="submitLable">&emsp;</div>
					<div class="submitValue">
						<el-button type="primary" @click="configWithdraw">确认提现</el-button>
					</div>
				</div>
			</div>

		</div>

		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="dialogCenter fl">
				<img :src="require('@/static/image/index/Vector.png')" style="width: 76px;height: 76px;" />
				<span class="deleteTitle">删除提现账户</span>
				<span class="deleteDetaile">您确定要删除提现账户吗？<br />删除后数据将无法恢复哦～</span>

			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button style="background-color: #FF5C00;color: #fff;" @click="deleteAccount">确 定</el-button>
			</span>
		</el-dialog>


		<div class="popUp" v-if="realShow">
			<div style="width: 100%;justify-content: center;position: relative;" class="fl">
				<img style="width: 522px;height:166px;position: absolute;top: 100px;"
					:src="require('@/static/image/mine/payPassword.png')" />
				<div class="popCount">
					<img class="close" @click="realShow = false" :src="require('@/static/image/index/close.png')" />
					<div class="popTitle">请输入密码</div>
					<el-input v-prevent-space v-model="input" class="popInput" placeholder="请输入您的支付密码"></el-input>
					<div class="popText">未设置支付密码？<span style="color: #0085FF;">立即设置</span></div>
					<div class="withdraw" @click="realShow = false">立即提现</div>
				</div>
			</div>
		</div>


		<div class="popUp" v-if="passShow">
			<div style="width: 100%;justify-content: center;position: relative;" class="fl">
				<img style="width: 522px;height:166px;position: absolute;top: 100px;"
					:src="require('@/static/image/index/payImage1.png')" />
				<div class="popCount">
					<img class="close" @click="passShow = false" :src="require('@/static/image/index/close.png')" />
					<div class="payment-password-input" v-if="showPass">
						<input v-prevent-space ref="inputList" :ref="`input${index}`"
							v-for="(item, index) in passwordLength" :key="index" type="text" v-model="password[index]"
							@input="handleInput($event, index)" @keydown="onKeydownBackSpace($event,index)" />
						<i class="el-icon-view" @click="showPass = !showPass" v-if="!showPass"
							style="font-size: 28px;margin-left: 10px;"></i>
						<i class="el-icon-view" @click="showPass = !showPass" v-else
							style="font-size: 28px;margin-left: 10px;color: #0085FF;"></i>

					</div>
					<div class="payment-password-input " v-else>
						<input v-prevent-space ref="inputList" :ref="`input${index}`"
							v-for="(item, index) in passwordLength" :key="index" type="password"
							v-model="password[index]" autoComplete="new-password" @input="handleInput($event, index)"
							@keydown="onKeydownBackSpace($event,index)" />
						<i class="el-icon-view" @click="showPass = !showPass" v-if="!showPass"
							style="font-size: 28px;margin-left: 10px;"></i>
						<i class="el-icon-view" @click="showPass = !showPass" v-else
							style="font-size: 28px;margin-left: 10px;color: #0085FF;"></i>
					</div>

					<div class="payOrder">
						<span>提现金额： </span>
						<div>
							<span
								style="color: #FF5C00;font-weight: bold;font-size: 36px;">{{withdrawForm.price}}</span>
							<span style="color: #FF5C00;">元</span>
						</div>
					</div>
					<!-- this.withDraw() -->
					<el-button @click="$noMultipleClicks(withDraw)" style="width: 234px;margin-top: 30px;" type="primary">立即提现</el-button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		withdrawList,
		withdrawAdd,
		withdrawGetUrl,
		withdrawApply,
		withdrawAccountDelete,
		getUserMessage,
		userCenter,
		fush,
		realInfo
	} from '@/api/index.js'

	import {
		smsSend
	} from '@/api/public.js'
	export default {
		data() {
			return {
				showPass: false,
				deleteId: '',

				dialogVisible: false,
				passShow: false,
				//* 在data中创建一个noClick默认启动
				noClick: true,
				realShow: false,
				password: Array(6).fill(''), // 初始化一个长度为6的数组用于存储密码
				passwordLength: 6, // 支付密码的长度
				state: 0,
				// 倒计时
				countDown: 0,
				countdownInterval: null,
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				accountList: [],
				withAccount:[],
				userInfo: {},
				withdrawForm: {
					accountId: '',
					password: '',
					price: ''
				},
				addForm: {
					// 账户类型1银行卡 2支付宝 3微信
					bankType: 2,
					name: '',
					phone: '',
					againPhone: '',
					// code: '',
					userId: ''
				},
				addImage: '',
				payPassword:'',
				debounce:false,
				realName:''
			}
		},
		created() {
			this.getList()
			this.getUrl()

			userCenter().then(res => {
				if (res.code == 200) {
					this.userInfo = res.result
					this.$store.commit('saveUserInfo', res.result)
				}
			})
			fush().then(res => {
				this.payPassword = res.result.payPassword
			
			})
			realInfo().then(res => {
				if (res.result == null || res.result.idCardAudit == 0) {
					
				}else{
					this.realName = res.result.realName
					
				}
			})

		},
		methods: {
			// 添加提现账号
			accountAdd() {
				if (this.addForm.bankType == 2) {
					// if (this.addForm.name == '') {
					// 	this.message('请填写开户人名', 'error')
					// 	return
					// }
					if (this.addForm.phone == '') {
						this.message('请填写支付宝号', 'error')
						return
					}
					if (this.addForm.againPhone != this.addForm.phone) {
						this.message('支付宝账户不一致', 'error')
						return
					}
					// if (this.addForm.code == '') {
					// 	this.message('请填写短信验证码', 'error')
					// 	return
					// }
				}
				this.addForm.userId = this.userInfo.id
				withdrawAdd(this.addForm).then(res => {
					if (res.code == 200) {
						this.message(res.message, 'success')
						this.getList()
					}
				})
			},


			onKeydownBackSpace(event, index) {
				// 检查是否是删除键或退格键被按下  
				if (event.key === 'Delete' || event.key === 'Backspace') {
					this.password[index] = ''
					this.$nextTick(() => {
						const nextInput = this.$refs[`input${index - 1}`][0];
						if (nextInput) {
							nextInput.focus();
						}
					});
				}
			},
			handleInput(event, index) {
				if (event.inputType != 'deleteContentBackward') {
					// 当输入框的值改变时，只允许输入数字并限制输入长度
					if (event.target.value.length > 1) {
						this.password[index] = event.data
					}

					this.$nextTick(() => {
						const nextInput = this.$refs[`input${index + 1}`][0];
						if (nextInput) {
							nextInput.focus();
						}
					});


				}

			},
			withDraw() {
				this.withdrawForm.password = this.password.join('')
				withdrawApply(this.withdrawForm).then(res => {
					if (res.code == 200) {
						userCenter().then(data => {
							if (data.code == 200) {
								this.userInfo = data.result
								this.$store.commit('saveUserInfo', data.result)
							}
						})
						this.message('提现发起成功', 'success')
						this.passShow = false
						this.state = 2
					}
					this.passShow = false
				})
			},
			// 提现
			configWithdraw() {
				// 判断是否设置支付密码
				if (this.payPassword != undefined && this.payPassword != null && this.payPassword != '') {
					if (this.withdrawForm.price == '' || this.withdrawForm.price == 0) {
						this.message('请输入提现金额', 'error')
						return
					}
					if (this.withdrawForm.accountId == '') {
						this.message('请选择提现账户', 'error')
						return
					}
					this.password = Array(6).fill('')
					this.passShow = true
				} else {
					this.message('请先设置支付密码','error')
					setTimeout(()=>{
						this.$router.push('/mineIndex/30')
					},1500)
				}
				
			},
			// 获取添加的url
			getUrl() {
				withdrawGetUrl().then(res => {
					this.addImage = res.result
				})
			},
			getList() {
				withdrawList({
					pageNo: 1,
					pageSize: '1000',
					userMessageId: this.$store.state.userInfo.id
				}).then(res => {
					if (res.code == 200) {
						this.accountList = res.result.records
						this.withAccount = []
						res.result.records.forEach(data=>{
							if(data.type ==2){
								this.withAccount.push(data)
							}
						})
						if (this.accountList.length == 0) {
							this.state = 0
						} else {
							this.state = 2
						}
					}
				})
			},
			// 获取验证码
			getCode(type, value) {
				if (!/^1\d{10}$/.test(value)) {
					this.message('手机号码格式不正确', 'error')
					return
				}
				if (this.countDown > 0) {
					return
				}
				smsSend({
					type: type,
					phoneNum: value
				}).then(res => {
					this.message('发送成功', 'success')
					// 开始倒计时  
					this.startCountdown();
				})
			},
			startCountdown() {
				this.countDown = 60; // 初始倒计时时间（秒）  
				this.countdownInterval = setInterval(() => {
					this.countDown--;
					if (this.countDown <= 0) {
						clearInterval(this.countdownInterval);
						this.countDown = 0;
						// 可以选择是否自动重新生成验证码  
						// this.generateCode();  
					}
				}, 1000);
			},
			// 删除弹框
			deleteShow(item) {
				this.dialogVisible = true
				this.deleteId = item.id
			},
			// 删除功能
			deleteAccount() {
				withdrawAccountDelete({
					id: this.deleteId
				}).then(res => {
					if (res.code == 200) {
						this.dialogVisible = false
						this.getList()
					}
				})
			}

		}


	}
</script>

<style scoped lang="scss">
	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, .3);
		overflow: hidden;
	}

	.popCount {
		z-index: 9999;
		position: relative;
		margin-top: 220px;
		display: flex;
		flex-direction: column;
		width: 522px;
		border-radius: 24px;
		align-items: center;
		background-color: #fff;
		padding-bottom: 30px;

		::v-deep .el-input__inner {
			text-align: center;
		}

		.popText {
			width: 264px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			// margin-top: 20px;
		}

		.popTitle {

			font-weight: 600;
			font-size: 26px;
			color: #1D223C;

		}

		.popInput {
			text-align: center;
			width: 296px;
			height: 64px;
			margin-top: 32px;
		}

		.withdraw {
			width: 396px;
			height: 64px;
			background: linear-gradient(90deg, #03B5FF 0%, #0085FF 100%);
			border-radius: 12px 12px 12px 12px;


			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF;
			line-height: 64px;
			text-align: center;
			margin-top: 131px;

		}



	}

	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.noAccount {
		width: 902px;
		// height: 255px;
		padding: 20px 0;
		background: #F8F8F8;
		border-radius: 12px 12px 12px 12px;
		justify-content: space-between;
		margin-left: 42px;
		margin-top: 68px;

		.accountLeft {
			display: flex;
			flex-direction: column;
			margin-left: 40px;
		}

		.account1 {
			font-weight: 600;
			font-size: 18px;
			color: #1D223C;
		}

		.account2 {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-top: 10px;

		}



	}

	.toAdd {
		width: 190px;
		height: 44px;
		border-radius: 8px 8px 8px 8px;
		border: 2px solid #0085FF;
		font-weight: 600;
		font-size: 14px;
		color: #0085FF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;
		cursor: pointer;
	}

	.toAdd1 {
		width: 190px;
		height: 44px;
		border-radius: 8px 8px 8px 8px;
		background-color: #0085FF;
		font-weight: 600;
		font-size: 14px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;
		margin-left: 42px;
		margin-right: 12px;
		cursor: pointer;
	}

	.remind {
		margin-left: 42px;
		margin-top: 50px;

		.remind1 {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
			margin-bottom: 15px;

		}

		.remind2 {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			margin-bottom: 10px;
		}
	}

	.formItem {
		margin-bottom: 30px;

		.submitLable {
			width: 200px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
				line-height: 30px;
				position: relative;
			}

		}

		.submitValue {
			width: 336px;
			margin: 0 20px;
			position: relative;
		}

		.lableNumber {
			margin: 0 20px;


			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;


		}

		.submitRemark {
			width: 40%;
			color: #A5A7B1;
		}
	}

	.submitForm {
		margin-top: 50px;
	}

	.lablePhone {
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
	}

	.getCode {
		position: absolute;
		right: 5px;
		line-height: 40px;
		font-size: 14px;
		color: #0085FF;
	}

	.getCode::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: -10px;
		top: 10px;
		background-color: #ECECEC;
	}

	.typeList {
		display: flex;
		justify-content: space-between;
	}

	.withType {
		width: 160px;
		height: 46px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #DEDEDE;
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
		line-height: 46px;
		text-align: center;
	}

	.action {
		background: rgba(0, 133, 255, 0.1);
		border: 1px solid #0085FF;
		color: #0085FF;
		position: relative;
	}

	.actionImage {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.delete {
		width: 78px;
		height: 30px;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #FF3C3C;
		display: flex;
		align-items: center;
		justify-content: center;

		font-weight: 400;
		font-size: 14px;
		margin-left: 12px;
		color: #FF3C3C;

	}

	.dialogCenter {
		border-top: 1px solid #FAFAFA;
		border-bottom: 1px solid #FAFAFA;
		flex-direction: column;
		padding: 30px 0;

		.deleteTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
			text-align: center;
			margin: 20px;
		}

		.deleteDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}
	}

	.payment-password-input {
		margin-top: 30px;
	}

	.payment-password-input input {
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 10px;
		border: 1px solid #ccc;
		text-align: center;
	}

	.payment-password-input input:focus {
		border-color: #007bff;
	}

	.payOrder {
		width: 394px;
		height: 100px;
		background: #F6F8FA;
		border-radius: 12px 12px 12px 12px;
		margin-top: 40px;
		padding: 0 24px;
		color: #A5A7B1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.close {
		position: absolute;
		top: -100px;
		right: 10px;
		z-index: 999;
	}
</style>